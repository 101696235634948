export { testclass, testfunc2, arr } from "./m_test";
export { gotoRevinfo, setCookie, getCookie, deleteCookie, deleteAllCookies } from "./common";
import "./ins.prototypes";
export { multi_carousel } from "./multi.carousel";
export { search_option_type0 } from "../../Pages/_/search.option.type0";
export { auth_phone_sms } from "../../Pages/_/auth_phone_sms";
export { topmenu } from "../../Pages/_/topmenu";
export { findaccount } from "../../Pages/account/findaccount";
export { join_account } from "../../Pages/account/join";
export { login_account } from "../../Pages/account/login";
export { cs_submenu } from "../../Pages/customercenter/_/cs_submenu";
export { carmodel } from "../../Pages/search_car/carmodel";
export { select_rentcar } from "../../Pages/search_car/select_rentcar";
export { reserve_car_SearchOption } from "../../Pages/search_car/_/reserve_car_SearchOption";
export { step00startrev } from "../../Pages/dorev/step00startrev";
export { agree_rules } from "../../Pages/dorev/_/agree_rules";
export { member_index } from "../../Pages/member/member_index";
export { member_submenu } from "../../Pages/member/_/member_submenu";
export { cancelrev } from "../../Pages/reservation/cancelrev";
export { userverification } from "../../Pages/reservation/userverification";
export { writereview } from "../../Pages/reservation/writereview";
export { RevInfo } from "../../Pages/reservation/_/RevInfo";
export { RevInfoFeePay } from "../../Pages/reservation/_/RevInfoFeePay";
export { init_review_page, md_under_scroll } from "../../Pages/review/review";
export { test_func } from "../../Pages/test"; // site.css 가 생성되기 위해서 필요(임시), 다른데서 css import 하는 ts 파일 있으면 꼭 필요한 것은 아님.
