import $ from 'jquery';
import { Korean } from "flatpickr/dist/l10n/ko";
import flatPickr from 'vue-flatpickr-component';
import Vue from 'vue';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import "./search.option.type0.scss";
export function search_option_type0(v_opt) {
    Vue.filter('numFormat', numFormat(numeral));
    dayjs.locale('ko');
    Object.defineProperties(Vue.prototype, { $date: { get: function () { return dayjs; } } });
    var app = new Vue({
        el: v_opt.vue_elem_id,
        data: {
            opt: v_opt,
            showModal: false,
            tabIndex: 0,
            date_from_to: v_opt["출발일시"].substring(0, 10) + " ~ " + v_opt["반납일시"].substring(0, 10),
            get sd() {
                return this.date_from_to.split('~')[0].trim();
            },
            get ed() {
                if (this.date_from_to.split('~').length == 2) {
                    return this.date_from_to.split('~')[1].trim();
                }
                else if (this.sdatecnt == 2) {
                    return this.date_from_to.split('~')[0].trim();
                }
                else
                    return '';
            },
            get totalHours() {
                var dt_s = this.sd + "T" + this.st.hour + ":" + this.st.min, dt_e = this.ed + "T" + this.et.hour + ":" + this.et.min;
                var df4 = dayjs(dt_e).diff(dayjs(dt_s), 'minute');
                return Math.ceil(df4 / 60);
            },
            get totalHoursDisplay() {
                var dt_s = dayjs(v_opt["출발일시"]);
                var dt_e = dayjs(v_opt["반납일시"]);
                var df4 = dayjs(dt_e).diff(dayjs(dt_s), 'minute');
                return Math.ceil(df4 / 60);
            },
            sdatecnt: 2,
            st: { hour: v_opt["출발일시"].substring(11, 13), min: v_opt["출발일시"].substring(14, 16) },
            et: { hour: v_opt["반납일시"].substring(11, 13), min: v_opt["반납일시"].substring(14, 16) },
            cartypeAllSelected: v_opt['차종'] == '' || v_opt['차종'] == null,
            cartype: (v_opt['차종'] || '').split(',').filter(function (item) { return item != '전체' && item != ''; }),
            instype: v_opt['자차보험'],
            sqrytxt: v_opt['검색어'],
            driver_age: v_opt['나이'] || 0,
            driver_experience: v_opt['운전경력'] || 0,
            config: {
                inline: true,
                wrap: true,
                locale: Korean,
                minDate: "today",
                maxDate: new Date().fp_incr(150),
                mode: 'range',
                dateFormat: "Y-m-d",
                altInput: true,
                altInputClass: 'd-none',
                onChange: function (selectedDates, dateStr, instance) {
                    app.sdatecnt = selectedDates.length;
                }
            }
        },
        components: {
            flatPickr: flatPickr
        },
        created: function () {
        },
        methods: {
            cartypeChanged: function () {
                var _this = this;
                this.$nextTick(function () {
                    _this.sqrytxt = ''; //차종 선택 될 때 검색어 reset하기.
                    if (_this.cartype.length == 0 || _this.cartype.length == _this.opt.cartypes.length) {
                        _this.cartype = [];
                        _this.cartypeAllSelected = true;
                    }
                    else {
                        _this.cartypeAllSelected = false;
                    }
                });
            },
            QryTxtChanged: function () {
                this.cartype = []; //검색어 입력시 모든차종 검색되도록..
                this.cartypeAllSelected = true;
            },
            onClickShowSearchModal: function () {
                $('#nav-date-tab').tab('show'); //날짜창을 기본으로.
                $('#bv-modal-search-condition').modal('show');
            },
            onClickShowTab: function (tabid) {
                this.tabChanged(tabid);
            },
            selectAllCartype: function (evt) {
                evt.target.checked = true;
                this.cartypeAllSelected = true;
                this.cartype = [];
            },
            tabChanged: function (tabid) {
                if (tabid == 'nav-condition-tab') {
                    if (this.date_from_to.split('~').length == 2) {
                        if (this.totalHours > 720) {
                            alert('최대 30일까지 대여가능. \n 30일이상 대여시 30일씩 나눠서 이용 하세요.');
                        }
                    }
                    else if (this.sdatecnt != 2) {
                        alert('날짜선택이 완료되지 않았습니다. 대여/반납일을 선택하세요.');
                        return;
                    }
                }
                $("#" + tabid).tab('show');
            },
            onclickGoSearch: function (event) {
                var _this = this;
                var sdt = dayjs(this.sd + " " + this.st.hour + ":" + this.st.min), edt = dayjs(this.ed + " " + this.et.hour + ":" + this.et.min);
                if (!edt.isValid()) {
                    edt = sdt.add(1, 'days');
                }
                if (this.totalHours <= 0) {
                    alert('렌트카의 대여 기간은 1시간 이상 이어야합니다.');
                    return;
                }
                if (sdt.isBefore(dayjs().add(2, 'hours'))) {
                    alert('대여시간 까지 최소 2시간 이상 남아있어야 예약할 수 있습니다.');
                    return;
                }
                if (this.totalHours > 720) {
                    alert('최대 30일까지 대여가능. \n 30일이상 대여시 30일씩 나눠서 이용 하세요.');
                }
                if (this.opt.checkReturn08.toLowerCase() == 'true' && edt.hour() < 8) {
                    if (this.instype.indexOf('완전') < 0 && this.instype.indexOf('고급') < 0) {
                        alert('자차보험을 선택하세요. \n\r08시 이전 반납시 고급(완전)자차 가입필수!');
                        return;
                    }
                }
                var url = this.opt.turl;
                if (this.driver_age > 0)
                    url = url + ("age=" + this.driver_age + "&");
                if (this.driver_experience > 0)
                    url = url + ("experience=" + this.driver_experience + "&");
                if (this.sqrytxt && this.sqrytxt.trim())
                    url = url + ("sqrytxt=" + this.sqrytxt + "&");
                if (this.cartype && this.cartype.length > 0 && !this.cartypeAllSelected) {
                    this.cartype = this.cartype.filter(function (x) { return (x && _this.opt.cartypes.includes(x)); });
                    url = url + ("cartype=" + this.cartype.join(',') + "&");
                }
                //qrystr에 없으면 이전값이 유지되는 qry
                if (this.instype && this.instype.trim() && this.instype != this.opt['자차보험'])
                    url = url + ("instype=" + this.instype + "&");
                if (!sdt.isSame(this.opt['출발일시']))
                    url = url + ("st=" + sdt.format("YYYY-MM-DD HH:mm") + "&");
                if (!edt.isSame(this.opt['반납일시']))
                    url = url + ("et=" + edt.format("YYYY-MM-DD HH:mm") + "&");
                this.showModal = false;
                $('#bv-modal-search-condition').modal('hide');
                $('#modal_on_data_loading').modal('show');
                window.location.href = encodeURI(url);
            }
        }
    });
    window.addEventListener("pageshow", function (event) {
        if (event.persisted || (window.performance && window.performance.navigation.type == 2)) {
            $('#bv-modal-search-condition').modal('hide');
        }
    });
}
