import $ from 'jquery';
import "bootstrap";
import "jquery-bar-rating";
import Vue from 'vue';
//.ts로 변경시 Vue 사용에 문제가 있어서.js 형식사용
export function writereview() {
    $('select[name=rv_rating]').barrating({
        theme: 'bars-pill',
        initialRating: null,
        showValues: true,
        showSelectedRating: false,
        hoverState: false,
        onSelect: function (value, text, event) {
        }
    });
    var app = new Vue({
        el: '#write_review_app',
        data: {
            res: { ok: false, msg: null },
        },
        methods: {
            onClickWriteReview: function (event) {
                var v = [$("#div_write_review #rv_kindness option:selected").val(), $("#div_write_review #rv_convenience option:selected").val(), $("#div_write_review #rv_cleanliness option:selected").val()];
                var t = ['[친절도]', '[편의성]', '[청결도]'];
                if (!v[0] || !v[1] || !v[2]) {
                    alert("" + (v[0] ? '' : t[0]) + (v[1] ? '' : t[1]) + (v[2] ? '' : t[2]) + " 평가를 해 주세요.");
                }
                else {
                    if (!confirm('리뷰를 전송하시겠습니까?')) {
                        return;
                    }
                    var review_data = {
                        iid: $('#div_write_review').attr('iid'),
                        revnum: $('#div_write_review').attr('revnum'),
                        kindness: v[0],
                        convenience: v[1],
                        cleanliness: v[2],
                        review: $("#div_write_review #rv_text").val(),
                        EvtIDs: $("#applyeventdiv input:checkbox[name='chk_event']:checked").map(function (idx) { return $(this).val(); }).get().join()
                    };
                    var tObj = this;
                    $.ajax({
                        type: "POST",
                        url: '/reservation/writereview?handler=WriteReview&' + Date.now(),
                        data: review_data,
                        headers: { RequestVerificationToken: $('input:hidden[name="__RequestVerificationToken"]').val() },
                        success: function (res) {
                            console.log(res);
                            if (res.ok === true) { //{ ok = true, ea.포인트, ea.참여이벤트수, ea.발행쿠폰명들, ea.참여이벤트명들 }
                                res['msg'] = '리뷰를 작성해 주셔서 감사합니다.<br />고객님께서 작성하신리뷰는 서비스 개선을 위해 참고하겠습니다.';
                                tObj.res = res;
                                //로그인안내창과 렌트정보창 순서 변경
                                $('#review_logininfo').addClass('order-2');
                                $('#review_carinfo').addClass('order-1');
                                $('#frm_writereview').hide();
                                $('#review_logininfo').hide();
                            }
                            else {
                                alert('리뷰저장 실패');
                            }
                        },
                        error: function (res) {
                            console.log(JSON.stringify(res));
                            alert('write review jf8aaqu747628 error!');
                        }
                    });
                }
            }
        }
    });
}
