import $ from 'jquery';
import { gotoRevinfo } from "../../wwwroot/js/common";
export function userverification() {
    $('#userverification_form #btn_continue').on('click', function (e) {
        e.preventDefault();
        var $form = $('#userverification_form');
        var name = $form.find("input[name='Name']").val();
        var revnum = $form.find("input[name='Revnum']").val();
        if ($form.valid()) {
            gotoRevinfo(revnum, name);
        }
    });
}
