import $ from 'jquery';
export function member_index() {
    $("#memberindex_form #btn_withdrawal").click(function (e) {
        var yes = confirm('회원탈퇴시 회원정보가 삭제됩니다. 탈퇴 하시겠습니까?');
        if (!yes) {
            e.preventDefault();
            return false;
        }
        return true;
    });
}
;
