import $ from 'jquery';
import "bootstrap";
/* Bootstrap 4 Multiple Item Carousel */
/*https://www.jqueryscript.net/slider/responsive-bootstrap-carousel-multiple-items.html*/
/*css내용 수정됨. js내용은 완전히 바뀜. - ins*/
/*********************************************************************/
/*
    .multi-carousel 정사이즈로 표시하기  class="col-lg-4 col-md-6 col-12"
    .multi-carousel-s :작은사이즈로 표시하기 class="col-lg-2 col-md-3 col-6"
*/
export function multi_carousel() {
    //bootstrap의 기능 : carousel 
    $('.multi-carousel,.multi-carousel-s').carousel({
        interval: 50000,
        wrap: false
    });
    $('.multi-carousel,.multi-carousel-s').each(function () {
        var c_items = $(this).find('.carousel-item');
        var items = new Array();
        for (var i = 0; i < c_items.length; i++) {
            var ci = $(c_items[i]).children(':first-child').clone();
            items.push(ci);
        }
        c_items.each(function (index, item) {
            for (var i = 1; i < c_items.length; i++) { //i=1부터: 자기자신은 추가하지 않기..
                items[(index + i) % c_items.length].clone().appendTo($(this));
            }
        });
    });
}
