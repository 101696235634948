import $ from 'jquery';
export function cs_submenu() {
    console.log('cs_submenu');
    var subject = '';
    if (window.location.pathname.toLowerCase().indexOf('/customercenter/troublereport') >= 0) {
        subject = 'troublereport';
    }
    else if (window.location.pathname.toLowerCase().indexOf('/customercenter/event') >= 0) {
        subject = 'event_on';
    }
    else if (window.location.pathname.toLowerCase().indexOf('/customercenter/viewevent') >= 0) {
        subject = 'event_on';
    }
    else {
        subject = window.location.href.ins_getQueryString('subject') || 'faq';
    }
    console.log(subject);
    $('#customercenter_submenu #' + subject).addClass('active');
}
