import $ from 'jquery';
export function auth_phone_sms(auth_phone_ctl_id) {
    var timerVar = null;
    var seconds = -1;
    function myTimer() {
        if (seconds >= 0) {
            $('#auth_phone_sms #authcode_timer').text(Math.floor(seconds / 60) + '분' + seconds % 60 + '초');
        }
        else { //인증번호 입력시간초과시..
            $('#auth_phone_sms #authcode_timer').text('입력시간초과');
            $('#auth_phone_sms #authcode_msg').text('');
            $('#auth_phone_sms #btn_send_authcode').show(); //.prop("disabled", false);
            $('#auth_phone_sms #txt_authcode').hide();
            $('#auth_phone_sms #btn_check_authcode').hide();
            if (timerVar) {
                clearInterval(timerVar);
            }
        }
        seconds--;
    }
    $('#auth_phone_sms #txt_authcode').on('focus', function (e) {
        $('#auth_phone_sms #authcode_msg').text('');
    });
    $('#auth_phone_sms #btn_send_authcode').on('click', function (e) {
        var phone = $('#' + auth_phone_ctl_id).val();
        if (!phone || phone.length < 1) {
            phone = $('#' + auth_phone_ctl_id).text();
        }
        phone = phone.replace(/-/gi, "").replace(/\./gi, "").replace(/\ /gi, "");
        var reg = /^\d*$/;
        if (!reg.test(phone) || phone.length < 10) {
            alert('올바른 휴대전화번호인지 확인바랍니다.');
            return;
        }
        $('#auth_phone_sms #authcode_timer').text('');
        var user_data = {
            revnum: '0',
            phone: phone,
            alength: $('#' + auth_phone_ctl_id).attr('alength')
        };
        $.ajax({
            type: "POST",
            url: '/api/shared/smssendauthcode?now=' + Date.now(),
            data: user_data,
            headers: { RequestVerificationToken: $('input:hidden[name="__RequestVerificationToken"]').val() },
            //beforeSend: function (xhr) { xhr.setRequestHeader("XSRF-TOKEN", $('input:hidden[name="__RequestVerificationToken"]').val()); },
            success: function (res) {
                //console.log(res);
                if (res.ok == 'true' || res.ok == true) {
                    $('#auth_phone_sms #btn_send_authcode').hide(); //.prop("disabled", true);
                    $('#auth_phone_sms #btn_check_authcode').show();
                    $('#auth_phone_sms #txt_authcode').show();
                    $('#auth_phone_sms #txt_authcode').text();
                    seconds = 180; //3분
                    timerVar = setInterval(myTimer, 1000);
                    alert('인증번호를 보냈습니다. 입력바랍니다.');
                }
                else {
                    ;
                }
            },
            error: function (res) {
                console.log(JSON.stringify(res));
                alert('eeif38tr473 isAuthenticated error!');
            }
        });
    });
    $('#auth_phone_sms #btn_check_authcode').on('click', function (e) {
        var phone = $('#' + auth_phone_ctl_id).val();
        if (!phone || phone.length < 1) {
            phone = $('#' + auth_phone_ctl_id).text();
        }
        var user_data = {
            revnum: '0',
            phone: phone,
            code: $('#auth_phone_sms #txt_authcode').val()
        };
        $.ajax({
            type: "POST",
            url: '/api/shared/smscheckauthcode?now=' + Date.now(),
            data: user_data,
            headers: { RequestVerificationToken: $('input:hidden[name="__RequestVerificationToken"]').val() },
            //beforeSend: function (xhr) { xhr.setRequestHeader("XSRF-TOKEN", $('input:hidden[name="__RequestVerificationToken"]').val());},
            success: function (res) {
                if (res.ok == 'true' || res.ok == true) { //코드가 맞으면..
                    $('#' + auth_phone_ctl_id).attr('isok', true); //isok = true ----------------------
                    $('#auth_phone_sms #btn_check_authcode').hide();
                    $('#auth_phone_sms #txt_authcode').hide(); //.prop("disabled", true);
                    $('#auth_phone_sms #auth_ok').show();
                    if (timerVar) {
                        clearInterval(timerVar);
                        $('#auth_phone_sms #authcode_timer').text('');
                    }
                }
                else {
                    alert('인증번호가 일치 하지 않습니다.');
                    //$('#auth_phone_sms #authcode_msg').text('인증번호가 일치 하지 않습니다.');
                }
            },
            error: function (res) {
                console.log(JSON.stringify(res));
                alert('eeif45io73 isAuthenticated error!');
            }
        });
    });
}
