import $ from 'jquery';
export function topmenu() {
    var pathname = window.location.pathname;
    var a = $('#rentinjeju_mainmenu #side-menu ul.navbar-nav a').filter(function () {
        return pathname.indexOf(this.pathname) == 0;
    });
    if (a && a.length > 0) {
        a.first().parent().addClass('active');
    }
    $('.id_menu_rentcar_search').click(function () {
        $('#modal_on_data_loading').modal('show'); //로딩중 이미지 대화상자 띄우기...5..4..3..2..1..
    });
    window.addEventListener("pageshow", function (event) {
        if (event.persisted || (window.performance && window.performance.navigation.type == 2)) {
            $('#modal_on_data_loading').modal('hide');
        }
    });
    $.ajax({
        type: "GET",
        url: '/api/account/isAuthenticated?' + Date.now(),
        success: function (response) {
            if (response.ok == 'true' || response.ok == true) {
                $('#side-menu #nav_menu_logout_sm').css('display', 'flex');
                $('#rentinjeju_mainmenu #menu_logout').css('display', 'flex');
                //$('#rentinjeju_mainmenu #menu_logout_sm').css('display', 'block');
            }
            else {
                $('#side-menu #nav_menu_login_sm').css('display', 'flex');
                $('#rentinjeju_mainmenu #menu_login').css('display', 'flex');
                //$('#rentinjeju_mainmenu #menu_login_sm').css('display', 'block');
            }
        },
        error: function (response) {
            console.log(JSON.stringify(response));
            alert('eheif373 isAuthenticated error!');
        }
    });
}
// 모바일창에서 표시되는 사이드메뉴, 영역외 DIV 클릭시 메뉴 닫힘관련 스크립트 (양홍일 추가)
$(document).ready(function () {
    $(document).click(function (event) {
        var clickover = $(event.target);
        var _opened = $(".navbar-collapse").hasClass("show");
        if (_opened === true && clickover.hasClass("sidebg")) {
            $(".navbar-toggler").click();
        }
    });
});
