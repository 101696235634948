import $ from 'jquery';
export function setCookie(name, value, hours) {
    if (name) {
        var expires = "";
        if (hours) {
            var date = new Date();
            date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        else {
            expires = "";
        }
        if (typeof value == 'object') {
            //value = JSON.stringify(value);
            throw 'object는 쿠키 장 안됨.JSON.stringify(value) 후 쿠키에 저장하세요.';
        }
        //console.log(name + ':' + typeof (value) + ' : ' + value);
        document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
        return value;
    }
    return null;
}
// 쿠키 가져오기
export function getCookie(name) {
    var nameEQ = decodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ')
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0)
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}
export function deleteCookie(cookieName) {
    if (cookieName) {
        setCookie(cookieName, '', -1);
    }
}
export function deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}
//예약페이지로가기..
export function gotoRevinfo(revnum, name) {
    setCookie('coo_go_rev_info', JSON.stringify({ revnum: revnum, name: name }), 1 / 120);
    window.location.href = '/reservation/reservationdetails';
}
//$(function () {
//    $('[data-toggle="tooltip"]').tooltip();
//});
//function getHtmlString(obj) { //jquery obj 에서 html string 얻기.
//    if ('outerHTML' in obj) {
//        return obj.outerHTML;
//    }
//    var div = document.createElement("div");
//    div.appendChild(obj.cloneNode(true));
//    return div.innerHTML;
//};
//동적 이미지 로딩
$(document).ready(function () {
    if (document.body.getAttribute('isdev') == 'true') {
        // return;
    }
    var imgs = $('.dynamic_load_img');
    var load_images = (function () {
        var imgs = $('.dynamic_load_img');
        return function () {
            if (imgs && imgs.length > 0) {
                var sc_top = document.documentElement.scrollTop, sc_bottom = document.documentElement.scrollTop + document.documentElement.clientHeight;
                imgs.each(function (idx) {
                    var i_top = sc_top + imgs[idx].getBoundingClientRect().top, i_bottom = sc_top + imgs[idx].getBoundingClientRect().bottom;
                    if (imgs[idx].i_load != true && sc_top < i_bottom && sc_bottom > i_top) {
                        imgs[idx].setAttribute('src', imgs[idx].getAttribute('i_src'));
                        imgs[idx].i_load = true;
                    }
                });
            }
        };
    })();
    //최초1회 로드.
    setTimeout(function () { load_images(); }, 0);
    $(window).scroll(function () {
        load_images();
    });
    $(window).resize(function () {
        load_images();
    });
});
