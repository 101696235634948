import $ from 'jquery';
import dayjs from 'dayjs';
import { setCookie } from "../../wwwroot/js/common";
export function carmodel() {
    console.log('carmodel');
    $('#carmodel_html .btn_goto_rev00').on('click', function (e) {
        e.preventDefault();
        var reservation_option = JSON.parse($('#carmodel_html #h_revcond').val());
        if (dayjs(reservation_option.st) <= dayjs().add(2, 'hours')) {
            alert('대여일시는 최소 2시간 이후 부터 렌트카 예약 가능.');
            return false;
        }
        reservation_option['israndom'] = $(this).attr('israndom');
        reservation_option['modelname'] = $(this).attr('modelname');
        reservation_option['scid'] = $(this).attr('scid');
        reservation_option['carfee'] = $(this).attr('carfee');
        reservation_option['insfee'] = $(this).attr('insfee') || 0;
        reservation_option['ins_scid'] = $(this).attr('ins_scid') || '';
        reservation_option['feesum'] = $(this).attr('feesum');
        reservation_option['cfeetype'] = "표준수수료";
        setCookie('coo_reservation_option', JSON.stringify(reservation_option), 1);
        //window.location.href = '/account/login?newrev=1&ReturnUrl=' + encodeURIComponent('/dorev/step00startrev');
        window.location.href = '/dorev/step00startrev';
    });
}
