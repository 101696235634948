import $ from 'jquery';
export function cancelrev() {
    var href = window.location.href;
    var revnum = href.ins_getQueryString('revnum');
    var name = href.ins_getQueryString('name');
    var phone = $('#user_phone').text();
    $('#rev_cancel_form #btn_do_cancel_rev').on('click', function (e) {
        if (!$('#rev_cancel_form #user_phone').attr('isok')) {
            alert('먼저 휴대전화 인증을 받으세요.');
            return;
        }
        var cancelfee = $('#rev_cancel_form #cancel_fee').text().ins_parseNumber();
        var c_msg = "예약취소 하시겠습니까?";
        if (cancelfee > 0) {
            c_msg = '예약취소시 취소수수료 ' + cancelfee + '원 차감 후 환불됩니다. 취소 하시겠습니까?';
        }
        if (!confirm(c_msg)) {
            return false;
        }
        var user_data = {
            revnum: revnum,
            phone: phone,
            cancelfee: cancelfee,
            creason: $('#rev_cancel_form #creason').val(),
            bankaccount: $('#rev_cancel_form #bankaccount').val()
        };
        $.ajax({
            type: "POST",
            url: '/reservation/cancelrev?handler=CancelRev&' + Date.now(),
            data: user_data,
            headers: { RequestVerificationToken: $('input:hidden[name="__RequestVerificationToken"]').val() },
            //beforeSend: function (xhr) { xhr.setRequestHeader("XSRF-TOKEN", $('input:hidden[name="__RequestVerificationToken"]').val());},
            success: function (res) {
                if (res.ok == 'true' || res.ok == true) {
                    alert('예약취소처리 되었습니다.');
                    window.location.href = '/reservation/reservationdetails?name=' + name + '&revnum=' + revnum;
                }
                else {
                    alert('예약취소실패');
                }
            },
            error: function (res) {
                console.log(JSON.stringify(res));
                alert('kwsjf847628 error!');
            }
        });
    });
}
