import $ from 'jquery';
export function agree_rules() {
    //사용법: $('#div_agree_rules').trigger('checkOK');
    $('#div_agree_rules').on('checkOK', function () {
        if ($('#div_agree_rules #chk_agree_rent').prop('checked') && $('#div_agree_rules #chk_agree_usage').prop('checked') &&
            $('#div_agree_rules #chk_agree_privacy').prop('checked') && $('#div_agree_rules #chk_agree_cpolicy').prop('checked')) {
            $('#div_agree_rules').data('isok', true);
            return true;
        }
        $('#div_agree_rules').data('isok', false);
        return false;
    });
    $('#div_agree_rules #agree_all').on('click', function (e) {
        $('#div_agree_rules #chk_agree_rent').prop('checked', this.checked);
        $('#div_agree_rules #chk_agree_usage').prop('checked', this.checked);
        $('#div_agree_rules #chk_agree_privacy').prop('checked', this.checked);
        $('#div_agree_rules #chk_agree_cpolicy').prop('checked', this.checked);
    });
}
