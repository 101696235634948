import $ from 'jquery';
import "bootstrap";
import "jquery-bar-rating";
export function RevInfo() {
    $('select[name=my_review]').barrating({
        theme: 'css-stars',
        showValues: false,
        showSelectedRating: false,
        hoverState: false,
        readonly: true,
        onSelect: function (value, text, event) {
        }
    });
}
