var arr = [1, 2, 3];
export { arr };
var testclass = /** @class */ (function () {
    function testclass(msg) {
        this.msg = null;
        this.msg = msg;
        console.log(msg);
    }
    return testclass;
}());
export { testclass };
export function testfunc2(msg) {
    //console.log('testfunc : ' + msg);
}
