import $ from 'jquery';
//goPay_Smartro() 함수 사용하기 위해 .js 파일형식 사용.
export function RevInfoFeePay() {
    $("#revinfo_feepay_form #btn_payNowCardModal").click(function () {
        var rev_info = JSON.parse($('#revinfo_feepay_form #h_revinfo').val());
        var amt = rev_info['결제예정금액'];
        var exp_pay_ids = $("#revinfo_feepay_form #h_payNowCardIds").val(); //납입예정목록
        if (!amt || +amt == 0 || exp_pay_ids.length <= 2) {
            alert('카드결제를 진행할수없습니다. 납입예정없음! 고객센터로 문의바랍니다.');
            return;
        }
        //결제진행하기전에 암호화키 가지고와서 세팅하기
        $.ajax({
            type: "POST",
            url: '/api/smartro/payinfo?handler=encryptData&' + Date.now(),
            data: { amt: amt },
            headers: { RequestVerificationToken: $('input:hidden[name="__RequestVerificationToken"]').val() },
            success: function (response) {
                $("#rev_card_form #MID").val(response.mid);
                $("#rev_card_form #Amt").val(amt);
                $("#rev_card_form #UserIP").val(response.clientip);
                $("#rev_card_form #EncryptData").val(response.encryptData);
                $("#rev_card_form #ediDate").val(response.ediDate);
                var dinfo = "exp_pay_ids:" + exp_pay_ids;
                $("#rev_card_form #MallReserved").val(dinfo);
                goPay_Smartro(rev_info);
            },
            error: function (response) {
                alert('카드결제를 진행할수없습니다. 암호화실패! 고객센터로 문의바랍니다.');
                return;
            }
        });
    });
}
