import $ from 'jquery';
import dayjs from 'dayjs';
import { setCookie } from "../../wwwroot/js/common";
export function select_rentcar() {
    //SelectInnerCheckBox 안에 있는 체크박스를 선택하는 스크립트
    $('#id_select_rentcar .Select_InnerCheckBox').on('click', function (e) {
        $(this).find('input').prop('checked', true);
        $(this).find('input').change();
    });
    $('#id_select_rentcar [name=rdo_ins]').change(function () {
        if ($(this).is(':checked')) {
            var rs = $(this); //.attr('id');
            var cfee = rs.attr('carfee').ins_parseNumber();
            var ifee = rs.attr('insfee').ins_parseNumber();
            var fsum = cfee + ifee;
            ////h_car_scid, h_instype, h_ins_scid, h_carfee, h_insfee, h_feesum
            //scid="@차량만.차.Scid" carfee="@차량만.cfee.ToString()" i_type="" i_scid="" insfee="0"            
            $('#id_select_rentcar #h_car_scid').val(rs.attr('scid'));
            $('#id_select_rentcar #h_carfee').val(cfee);
            $('#id_select_rentcar #h_instype').val(rs.attr('i_type'));
            $('#id_select_rentcar #h_ins_scid').val(rs.attr('i_scid'));
            $('#id_select_rentcar #h_insfee').val(ifee);
            $('#id_select_rentcar #h_feesum').val(fsum);
            TraceRO();
        }
    });
    //back & forward 버튼 때문에 필요함.
    $('#id_select_rentcar [name=rdo_ins]:checked').change();
    $('#id_select_rentcar #btn_goto_rev00').on('click', function (e) {
        e.preventDefault();
        if (!$("#id_select_rentcar input[name=rdo_ins]:checked").attr('scid')) {
            alert('자차보험 가입여부를 선택하세요.');
            return false;
        }
        var reservation_option = JSON.parse($('#id_select_rentcar #h_revcond').val());
        if (dayjs(reservation_option.st) <= dayjs().add(2, 'hours')) {
            alert('대여일시는 최소 2시간 이후 부터 렌트카 예약 가능.');
            return false;
        }
        //h_car_scid, h_instype, h_ins_scid, h_carfee, h_insfee, h_feesum
        reservation_option['scid'] = $('#id_select_rentcar #h_car_scid').val();
        reservation_option['carfee'] = $('#id_select_rentcar #h_carfee').val();
        reservation_option['instype'] = $('#id_select_rentcar #h_instype').val();
        reservation_option['insfee'] = $('#id_select_rentcar #h_insfee').val();
        reservation_option['ins_scid'] = $('#id_select_rentcar #h_ins_scid').val();
        reservation_option['feesum'] = $('#id_select_rentcar #h_feesum').val();
        reservation_option['cfeetype'] = "표준수수료";
        setCookie('coo_reservation_option', JSON.stringify(reservation_option), 1);
        //console.log(reservation_option);
        window.location.href = '/dorev/step00startrev';
    });
    function TraceRO() {
        var reservation_option = JSON.parse($('#id_select_rentcar #h_revcond').val());
        reservation_option['scid'] = $('#id_select_rentcar #h_car_scid').val();
        reservation_option['carfee'] = $('#id_select_rentcar #h_carfee').val();
        reservation_option['instype'] = $('#id_select_rentcar #h_instype').val();
        reservation_option['insfee'] = $('#id_select_rentcar #h_insfee').val();
        reservation_option['ins_scid'] = $('#id_select_rentcar #h_ins_scid').val();
        reservation_option['feesum'] = $('#id_select_rentcar #h_feesum').val();
        reservation_option['cfeetype'] = "표준수수료";
        //console.log(reservation_option);
    }
}
