import $ from 'jquery';
import 'jquery-validation';
export function findaccount() {
    console.log('findaccount');
    //비밀전호 찾기
    $("#findpw_form #email").keydown(function () {
        $("#findpw_form #resultdiv").css('display', 'none');
        $('#findpw_form #btn_sendrequest').attr("disabled", false);
    });
    $('#findpw_form #btn_sendrequest').on('click', function (e) {
        e.preventDefault();
        var form = $('#findpw_form');
        var email = form.find("input[name='email']").val();
        $('#findpw_form #btn_sendrequest').attr("disabled", true);
        form.validate();
        if (form.valid()) {
            $.ajax({
                type: "POST",
                url: '/api/account/findpassword?' + Date.now(),
                data: {
                    email: email,
                },
                success: function (response) {
                    if (response.ok == 'true' || response.ok == true) {
                        $("#findpw_form #resultdiv").css('display', 'block');
                        $("#findpw_form #resultdiv").text(email + "\uB85C \uBCC0\uACBD\uB41C \uBE44\uBC00\uBC88\uD638 \uC774\uBA54\uC77C\uC744 \uBCF4\uB0C8\uC2B5\uB2C8\uB2E4. \uD655\uC778\uBC14\uB78D\uB2C8\uB2E4.");
                    }
                    else {
                        $('#findpw_form #btn_sendrequest').attr("disabled", false);
                        $("#findpw_form #resultdiv").css('display', 'block');
                        $("#findpw_form #resultdiv").text("잘못된요청!");
                    }
                },
                error: function (response) {
                    $('#findpw_form #btn_sendrequest').attr("disabled", false);
                    $("#findpw_form #resultdiv").css('display', 'block');
                    $("#findpw_form #resultdiv").text("잘못된요청!");
                }
            });
        }
    });
    //인증메일 다시 보내기 ---------------------------------------------------------------------------
    /*
    $("#emailauth_form #email").keydown(function () {
        $("#emailauth_form #resultdiv").css('display', 'none');
        $('#emailauth_form #btn_sendrequest').attr("disabled", false);
    });

    $('#emailauth_form #btn_sendrequest').on('click', function (e) {
        e.preventDefault();
        var form = $('#emailauth_form');
        var email = form.find("input[name='email']").val();

        $('#emailauth_form #btn_sendrequest').attr("disabled", true);
        form.validate();
        if (form.valid()) {
            $.ajax({
                type: "POST",
                url: '/api/account/sendemailauth?' + Date.now(),
                data: {
                    email: form.find("input[name='email']").val(),
                },
                success: function (response) {
                    if (response.ok == 'true' || response.ok == true) {
                        $("#emailauth_form #resultdiv").css('display', 'block');
                        $("#emailauth_form #resultdiv").text(`${email}로 인증 이메일을 보냈습니다. 확인바랍니다.`);
                    } else {
                        $('#emailauth_form #btn_sendrequest').attr("disabled", false);
                        $("#emailauth_form #resultdiv").css('display', 'block');
                        $("#emailauth_form #resultdiv").text(response.msg);
                    }
                },

                error: function (response) {
                    $('#emailauth_form #btn_sendrequest').attr("disabled", false);
                    $("#emailauth_form #resultdiv").css('display', 'block');
                    $("#emailauth_form #resultdiv").text("서버오류!");
                }
            });
        }
    });
    */
}
