import $ from 'jquery';
import 'jquery-validation';
export function join_account() {
    console.log('join_account');
    var email_checked = false;
    var purl = "/account/join";
    $("#member_join_form #emailaddr").keydown(function () {
        email_checked = false;
        $("#member_join_form #check_email").css('color', 'red');
        $("#member_join_form #email_check_ok").css('display', 'none');
    });
    $('#member_join_form #check_email').on('click', function (e) {
        e.preventDefault();
        var iemail = $("#member_join_form input[name='회원1.회원id']");
        if (!iemail.valid()) {
            console.log('invalid email format');
            return;
        }
        //http://www.talkingdotnet.com/handle-ajax-requests-in-asp-net-core-razor-pages/
        $.ajax({
            type: "POST",
            url: purl + '?handler=CheckEmail&' + Date.now(),
            data: { email: iemail.val() },
            headers: { RequestVerificationToken: $('input:hidden[name="__RequestVerificationToken"]').val() },
            //beforeSend: function (xhr) { xhr.setRequestHeader("XSRF-TOKEN", $('input:hidden[name="__RequestVerificationToken"]').val());},
            success: function (response) {
                if (response.ok == 'true' || response.ok == true) {
                    email_checked = true;
                    $("#member_join_form #check_email").css('color', 'green');
                    $("#member_join_form #email_check_ok").css('display', 'inline');
                }
                else {
                    email_checked = false;
                    $("#member_join_form #check_email").css('color', 'red');
                    $("#member_join_form #email_check_ok").css('display', 'none');
                    alert('사용할 수 없는 이메일 주소입니다.');
                }
            },
            error: function (response) {
                email_checked = false;
                alert('서버 오류!');
            }
        });
    });
    $('#member_join_form #btn_continue').on('click', function (e) {
        var $form = $('#member_join_form');
        if ($form.valid()) {
            e.preventDefault();
            if (!email_checked) {
                alert('이메일주소 중복 체크 필요함!');
                return;
            }
            if (!$('#chk_agree_usage').prop('checked') || !$('#chk_agree_privacy').prop('checked')) {
                alert('이용약관 및 개인정보취급방침에 동의해야 회원가입을 진행 할 수 있습니다.');
                return false;
            }
            $('#member_join_form #btn_continue').attr("disabled", true);
            //http://www.talkingdotnet.com/handle-ajax-requests-in-asp-net-core-razor-pages/
            $.ajax({
                type: "POST",
                url: purl + '?handler=RegisterMember&' + Date.now(),
                data: {
                    '회원1.회원id': $form.find("input[name='회원1.회원id']").val(),
                    '회원1.이름': $form.find("input[name='회원1.이름']").val(),
                    '회원1.비밀번호': $form.find("input[name='Password']").val(),
                    '회원1.휴대전화': $form.find("input[name='회원1.휴대전화']").val(),
                    '회원1.상태': '가입',
                    'Password': $form.find("input[name='Password']").val(),
                    'ConfirmPassword': $form.find("input[name='ConfirmPassword']").val(),
                    'EvtIDs': $("#applyeventdiv input:checkbox[name='chk_event']:checked").map(function (idx) { return $(this).val(); }).get().join()
                },
                headers: { RequestVerificationToken: $('input:hidden[name="__RequestVerificationToken"]').val() },
                //beforeSend: function (xhr) { xhr.setRequestHeader("XSRF-TOKEN", $('input:hidden[name="__RequestVerificationToken"]').val());},
                success: function (response) {
                    if (response.ok == 'true' || response.ok == true) {
                        $("#joinsuccess_div").css('display', 'block');
                        $("#joinform_div").css('display', 'none');
                        if (response.point > 0) {
                            $("#joinsuccess_div #event_point").text('이벤트: 고객가입 포인트 적립되었습니다. ' + response.point + '포인트');
                        }
                        if (response.cnames) {
                            $("#joinsuccess_div #event_coupon").text('이벤트: 할인쿠폰이 발급되었습니다. ' + response.cnames);
                        }
                        var rurl = window.location.href.ins_getQueryString('ReturnUrl');
                        if (rurl) {
                            window.location.href = decodeURIComponent(rurl);
                        }
                    }
                    else {
                        $("#errortextdiv").text(response.msg);
                        $("#errortextdiv").css('display', 'block');
                        $('#member_join_form #btn_continue').attr("disabled", false);
                    }
                },
                error: function (response) {
                    $("#errortextdiv").css('display', 'block');
                    $("#errortextdiv").text(response.msg);
                    $('#member_join_form #btn_continue').attr("disabled", false);
                }
            });
        }
    });
}
