import $ from 'jquery';
import dayjs from 'dayjs';
import flatpickr from "flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko";
//차량 검색 조건..
export function reserve_car_SearchOption() {
    var fp_option = {
        wrap: true,
        "locale": Korean,
        minDate: "today",
        maxDate: new Date().fp_incr(100),
        onChange: function (selectedDates, dateStr, instance) {
            if (instance.input.id == 'i_st') {
                ev.onChangeSt();
            }
            if (instance.input.id == 'i_et') {
                ev.onChangeEt();
            }
        }
    };
    //var pks = $("#car_search_option .flatpickr").flatpickr(fp_option);
    var pks = flatpickr("#car_search_option .flatpickr", fp_option);
    $("#car_search_option #i_st_hour").on('change', function (e) {
        ev.onChangeSt();
    });
    $("#car_search_option #i_st_min").on('change', function (e) {
        ev.onChangeSt();
    });
    if ($("#car_search_option #i_et_hour").val() == '21') { //최초1회
        $("#car_search_option #i_et_min").attr('disabled', true);
    }
    $("#car_search_option #i_et_hour").on('change', function (e) {
        var min_elem = $("#car_search_option #i_et_min");
        if ($(this).val() == '21') {
            min_elem.val('00');
            min_elem.attr('disabled', true);
        }
        else {
            min_elem.attr('disabled', false);
        }
        ev.onChangeEt();
    });
    $("#car_search_option #i_et_min").on('change', function (e) {
        ev.onChangeEt();
    });
    var ev = {
        hours: 0,
        get st() {
            return $("#car_search_option #i_st").val() + ' ' + $("#car_search_option #i_st_hour").val() + ':' + $("#car_search_option #i_st_min").val();
        },
        get et() {
            return $("#car_search_option #i_et").val() + ' ' + $("#car_search_option #i_et_hour").val() + ':' + $("#car_search_option #i_et_min").val();
        },
        get days() {
            return Math.abs(dayjs(this.st).diff(dayjs(this.et), 'days')) + 1;
        },
        onChangeSt: function () {
            if (dayjs(this.et).isBefore(this.st)) {
                var idx = pks.findIndex(function (k) { return k.input.id == 'i_et'; });
                pks[idx].setDate(this.st);
            }
            this.calHours();
        },
        onChangeEt: function () {
            if (dayjs(this.et).isBefore(this.st)) {
                var idx = pks.findIndex(function (k) { return k.input.id == 'i_st'; });
                pks[idx].setDate(this.et);
            }
            this.calHours();
        },
        calHours: function () {
            var hours = Math.ceil(dayjs(this.et).diff(dayjs(this.st), 'hours', true));
            var s = $('#car_search_option #dur_hours');
            s.text(hours);
            this.hours = hours;
        }
    };
    ev.calHours();
    $('#car_search_option #btn_search').click(function () {
        if (parseInt($('#car_search_option #dur_hours').text()) <= 0) {
            alert('렌트카의 대여 기간은 1시간 이상 이어야합니다.');
            return;
        }
        if (dayjs(ev.st).isBefore(dayjs().add(2, 'hours'))) {
            alert('대여시간 까지 최소 2시간 이상 남아있어야 예약할 수 있습니다.');
            return;
        }
        if (ev.hours > 720) { //ok
            alert('최대 30일까지 대여가능. \n 30일이상 대여시 30일씩 나눠서 이용 하세요.');
        }
        var sOpt = JSON.parse($('#car_search_option #h_sOption').val());
        var url = sOpt.turl;
        var o_cartype = $('#car_search_option').attr('cartype');
        var cartype = $("#car_search_option #dd_cartype").val() || '';
        if (cartype && cartype != '' && cartype != o_cartype) {
            url = url + 'cartype=' + cartype + '&';
        }
        var o_instype = $('#car_search_option').attr('instype');
        var instype = $("#car_search_option #dd_instype").val() || '';
        if (instype && instype != '' && instype != o_instype) {
            url = url + 'instype=' + instype + '&';
        }
        var o_st = dayjs($('#car_search_option').attr('st'));
        var o_et = dayjs($('#car_search_option').attr('et'));
        if (!dayjs(ev.st).isSame(o_st)) {
            url = url + 'st=' + ev.st;
        }
        if (!dayjs(ev.et).isSame(o_et)) {
            url = url + '&et=' + ev.et;
        }
        if (sOpt.checkReturn08 && +$("#car_search_option #i_et_hour").val() < 8) {
            if (instype.indexOf('완전') < 0 && instype.indexOf('고급') < 0) {
                alert('자차보험을 선택하세요. \n\r08시 이전 반납시 고급(완전)자차 가입필수!');
                return;
            }
        }
        //show modal
        $('#modal_on_data_loading').modal('show');
        window.location.href = encodeURI(url);
    });
}
