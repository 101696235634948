import $ from 'jquery';
import 'jquery-validation';
export function init_review_page() {
    console.log('review_list_form');
    $('#review_list_form #btn_sendrequest').on('click', function (event) {
        e.preventDefault();
        var form = $('#review_list_form');
        var searchtxt = form.find("input[name='searchtxt']").val().trim();
        if (searchtxt.length == 0) {
            alert("검색어를 입력하세요.");
            return;
        }
        window.location.href = '/review?sch=' + searchtxt;
    });
}
export function md_under_scroll(divid) {
    var target = $("#" + divid);
    var items = target.find('.items');
    if (items.length > 1) {
        var md_under_scroll_func = {
            outer: target,
            items: items,
            curr: 0,
            width: 0,
            interval: 3000,
            setinter: null,
            init: function () {
                this.outer.on('mouseover', function () {
                    md_under_scroll_func.mouseover();
                });
                this.outer.on('mouseout', function () {
                    md_under_scroll_func.mouseout();
                });
                for (var i = 0; i < this.items.length; i++) {
                    var c = $('<div class="page mx-2 rounded-circle" page="' + i + '" style="cursor:pointer;width:10px;height:10px;">');
                    $("#" + divid + "_bottom").append(c);
                }
                $("#" + divid + "_bottom .page").each(function (i) {
                    $(this).on('click', function () {
                        md_under_scroll_func.click($(this).attr('page'));
                    });
                });
                this.checkstate();
            },
            click: function (p) {
                //alert(p * this.width * -1);
                this.curr = p;
                this.items.stop().animate({ left: p * this.width * -1 });
                //현재값표시
                $("#" + divid + "_bottom .page").each(function (i) {
                    if (i == md_under_scroll_func.curr) {
                        $(this).css('background-color', '#ec6664');
                    }
                    else {
                        $(this).css('background-color', '#ddecf2');
                    }
                });
            },
            autonext: function () {
                md_under_scroll_func.curr++;
                if (md_under_scroll_func.curr >= md_under_scroll_func.items.length) {
                    md_under_scroll_func.curr = 0;
                    md_under_scroll_func.click(0);
                }
                else {
                    md_under_scroll_func.click(md_under_scroll_func.curr);
                }
                clearTimeout(md_under_scroll_func.setinter);
                md_under_scroll_func.setinter = setTimeout(md_under_scroll_func.autonext, md_under_scroll_func.interval);
            },
            resize: function () {
                this.checkstate();
            },
            checkstate: function () {
                clearTimeout(this.setinter);
                this.width = $(target).width();
                if ($(window).width() < 992) { //lg이하사이즈이면
                    this.items.css('left', this.curr * this.width * -1);
                    $("#" + divid + "_bottom .page").each(function (i) {
                        if (i == md_under_scroll_func.curr) {
                            $(this).css('background-color', '#ec6664');
                        }
                        else {
                            $(this).css('background-color', '#ddecf2');
                        }
                    });
                    this.setinter = setTimeout(this.autonext, this.interval);
                }
                else {
                    this.items.css('left', '0');
                }
            },
            mouseover: function () {
                clearTimeout(this.setinter);
            },
            mouseout: function () {
                if ($(window).width() < 992) { //lg이하사이즈이면
                    clearTimeout(this.setinter);
                    this.setinter = setTimeout(this.autonext, this.interval);
                }
            }
        };
        md_under_scroll_func.init();
        $(window).resize(function () {
            md_under_scroll_func.resize();
        });
    }
}
