Number.prototype.ins_addComma = function () {
    if (this === 0)
        return '0';
    var reg = /(^[+-]?\d+)(\d{3})/;
    var n = this + '';
    while (reg.test(n))
        n = n.replace(reg, '$1' + ',' + '$2');
    return n;
};
Number.prototype.ins_floorNumber = function (basis) {
    if (typeof basis === 'undefined' || basis === null || basis === 0)
        return this;
    basis = 1 / basis;
    return Math.floor(this * basis) / basis;
};
Number.prototype.ins_ceilNumber = function (basis) {
    if (typeof basis === 'undefined' || basis === null || basis === 0)
        return this;
    basis = 1 / basis;
    return Math.ceil(this * basis) / basis;
};
Number.prototype.ins_roundNumber = function (basis) {
    if (typeof basis === 'undefined' || basis === null || basis === 0)
        return this;
    basis = 1 / basis;
    return Math.round(this * basis) / basis;
};
String.prototype.ins_addComma = function () {
    var num = this.ins_parseNumber();
    if (isNaN(num))
        return num;
    return num.ins_addComma();
};
String.prototype.ins_removeComma = function () {
    var str = this + '';
    str = str.split(',').join('');
    return str;
};
String.prototype.ins_floorNumber = function (basis) {
    var num = this.ins_parseNumber();
    if (isNaN(num))
        return num;
    return num.ins_floorNumber(basis);
};
String.prototype.ins_roundNumber = function (basis) {
    var num = this.ins_parseNumber();
    if (isNaN(num))
        return num;
    return num.ins_roundNumber(basis);
};
String.prototype.ins_ceilNumber = function (basis) {
    var num = this.ins_parseNumber();
    if (isNaN(num))
        return num;
    return num.ins_ceilNumber(basis);
};
String.prototype.ins_parseNumber = function () {
    var num = NaN;
    try {
        if (this.replace(/^\s+|\s+$/g, "").ins_isNumeric()) {
            num = parseFloat(this.replace(/^\s+|\s+$/g, "").ins_removeComma());
        }
    }
    catch (e) {
        return NaN;
    }
    return num;
};
String.prototype.ins_isNumeric = function () {
    var ValidChars = "0123456789.,-";
    for (var i = 0; i < this.length; i++)
        if (ValidChars.indexOf(this.charAt(i)) === -1)
            return false;
    return true;
};
// Query String --------------------------------------------------------------------------------------------------------
String.prototype.ins_getQueryString = function (varName) {
    var url = this.split("?");
    var path = null;
    var query = null;
    if (url.length === 1)
        query = url[0];
    else if (url.length === 2) {
        path = url[0];
        query = url[1];
    }
    else
        return null;
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] === varName) {
            if (pair[1] === '')
                return null;
            return pair[1];
        }
    }
    return null;
};
String.prototype.ins_setQueryString = function (varName, varValue) {
    var url = this.split("?");
    var path = null;
    var query = '';
    if (url.length === 1)
        query = url[0];
    else if (url.length === 2) {
        path = url[0];
        query = url[1];
    }
    else
        return null;
    var newqry = varName + '=' + varValue;
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] === varName)
            continue;
        newqry += '&' + vars[i];
    }
    if (path === null)
        return newqry;
    else
        return path + "?" + newqry;
};
