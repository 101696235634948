require("./test.scss");
import $ from 'jquery';
import "jquery-bar-rating";
export function test_func(v_opt) {
}
export function test_star_rating() {
    $('select[name=rv_rating]').barrating({
        theme: 'css-stars',
        initialRating: null,
        showValues: false,
        showSelectedRating: false,
        hoverState: false
    });
    $('select[name=rv_rating]').each(function () {
        $(this).barrating('set', $(this).attr('rate'));
        $('select').barrating('readonly', true);
    });
}
