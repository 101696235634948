import $ from 'jquery';
import dayjs from 'dayjs';
import "bootstrap";
var axios = require('axios').default;
import { setCookie, getCookie, deleteCookie } from "../../wwwroot/js/common";
import Vue from 'vue';
//goPay_Smartro() 함수 사용하기 위해 .js 파일형식 사용.
export function step00startrev(payRatio) {
    var minPayRaitio = (payRatio ? payRatio : 0.4); //최소 40% 결제 요청
    var cro = getCookie('coo_reservation_option');
    if (cro == null) {
        return;
    }
    var reservation_option = JSON.parse(cro);
    reservation_option['entry_time'] = dayjs().format("YYYY-MM-DD HH:mm:ss");
    //console.log(reservation_option);
    var v_couponDisc = new Vue({
        el: '#couponDiscModal',
        data: {
            msg: '할인쿠폰 번호를 입력하세요.',
            couponNumber: '',
            couponList: [],
            selectedDcid: '',
            memberOwnedCouponList: []
        },
        created: function () {
            var cObj = this;
            axios.get('/api/DiscCoupon/getDefaultCouponList', {
                params: {
                    st: reservation_option['st'],
                    cid: reservation_option['cid'],
                }
            }).then(function (res) {
                if (res.data['ok'] == 'true' || res.data['ok'] == true) {
                    cObj.memberOwnedCouponList = res.data['couponList'] || [];
                    cObj.couponList = cObj.couponList.concat(cObj.memberOwnedCouponList);
                }
            }).catch(function (ex) {
                console.log(ex.message);
            });
        },
        methods: {
            findCouponNumber: function (e) {
                var cObj = this;
                this.selectedDcid = '';
                this.couponList = [].concat(cObj.memberOwnedCouponList);
                //RequestVerificationToken: $('input:hidden[name="__RequestVerificationToken"]').val()
                axios.post('/api/DiscCoupon/FindDiscCoupon', { couponnumber: this.couponNumber }, { headers: { "Content-type": "application/json" } })
                    .then(function (res) {
                    if (res.data['ok'] == 'true' || res.data['ok'] == true) {
                        cObj.msg = '입력한 할인쿠폰번호를 찾았습니다.';
                        var tlist = cObj.memberOwnedCouponList.concat(res.data['couponList']);
                        cObj.couponList = tlist.filter(function (thing, index) {
                            //return index == tlist.findIndex(function (obj) { //IE 때문에 적용못함..
                            //    return JSON.stringify(obj) == JSON.stringify(thing);
                            //});
                            var idx = -1;
                            for (var i = 0; i < tlist.length; i++) {
                                if (JSON.stringify(tlist[i]) == JSON.stringify(thing)) {
                                    idx = i;
                                    break;
                                }
                            }
                            return index == idx;
                        });
                    }
                    else {
                        cObj.msg = '잘못된 할인쿠폰 번호입니다.';
                    }
                }).catch(function (ex) {
                    cObj.msg = ex.message;
                });
            },
            applyCoupon: function () {
                var dnum = this.selectedDcid;
                //var coupon = this.couponList.find(function (x) { //IE 때문에 적용
                //    return x['쿠폰']['Dcid'] == dnum;
                //});
                var coupon = this.couponList.filter(function (x) {
                    return x['쿠폰']['Dcid'] == dnum;
                })[0];
                apply_disc_coupon(coupon || {});
            }
        }
    });
    //쿠폰할인 적용하기 클릭.
    function apply_disc_coupon(coupon) {
        var fee = 0;
        var couponid = (coupon['쿠폰'] || {})['Dcid'] || '';
        var noms = (coupon['쿠폰'] || {})['적용상품'] || '';
        var disc = +(coupon['쿠폰'] || {})['할인율'] || 0;
        var c_disc = 0, applycnt = 0;
        if (disc >= 1) { //금액으로 할인
            c_disc = disc;
            applycnt = 1;
        }
        else {
            var fee_sum = $("#form_step00startrev #h_feesum").val().ins_parseNumber();
            c_disc += (fee_sum * disc);
            applycnt++;
            //for (var k = 0; k < itemfees.length; k++) {
            //    fee = itemfees[k];
            //    if (noms.indexOf('판매가') >= 0 || noms.indexOf(fee['명목']) >= 0) {
            //        c_disc += ((+fee['판매가']) * disc);
            //        applycnt++;
            //    }
            //}
        }
        $('#form_step00startrev #couponDiscModal').modal('hide');
        c_disc = c_disc.ins_roundNumber(100);
        $("#form_step00startrev #disc_coupon_fee").text(c_disc);
        $("#form_step00startrev #disc_coupon_fee").prop('couponid', couponid);
        $("#form_step00startrev #disc_coupon_fee").prop('o_c_num', coupon['전용쿠폰번호'] || '');
        if (applycnt == 0 && couponid != '') {
            alert('예약내용중에 선택한 쿠폰의 할인대상 품목이 없으므로 할인이 적용되지 않습니다.');
        }
        ComputeFee();
    }
    //var itemfees = new Array();
    //var sf = $("#form_step00startrev #h_itemfees").val().split('#');
    //itemfees.push({ 명목: sf[0].split(':')[0], 판매가: sf[0].split(':')[1] });
    //itemfees.push({ 명목: sf[1].split(':')[0], 판매가: sf[1].split(':')[1] });
    //포인트 사용액 변경시..
    $("#form_step00startrev #disc_reserves_money").change(function () {
        var max = $(this).prop('max').ins_parseNumber();
        var cur = $(this).val().ins_parseNumber();
        if (!cur || cur < 0) {
            $(this).val(0);
            cur = 0;
        }
        if (cur > max) { //max: 포인트 금액
            cur = max;
        }
        $(this).val(cur.ins_floorNumber(100));
        ComputeFee();
    });
    //현장결제금액표시
    function divPayBalance_change_display() {
        var bamt = $("#form_step00startrev .fee_remain70").first().text().ins_parseNumber();
        if (bamt > 0) {
            $("#form_step00startrev .divPayBalance").each(function () {
                $(this).css('display', 'block');
            });
        }
        else {
            $("#form_step00startrev .divPayBalance").each(function () {
                $(this).css('display', 'none');
            });
        }
    }
    //결제금액(전액,계약금) 선택 변경시..
    $("#form_step00startrev #dd_pay_amt").change(function () {
        divPayBalance_change_display();
        var amt = $("#form_step00startrev #dd_pay_amt option:selected").val().ins_addComma();
        $("#form_step00startrev #fee_payamt").text(amt); //결제모달에 "입금하실금액" 표시
    });
    //할인쿠폰, 포인트 사용 변경시 요금 다시계산하기..
    function ComputeFee() {
        var fee_sum = $("#form_step00startrev #h_feesum").val().ins_parseNumber();
        var disc_coupon = 0, disc_reserves = 0;
        if ($("#form_step00startrev #disc_coupon_fee").length > 0) {
            disc_coupon = $("#form_step00startrev #disc_coupon_fee").text().ins_parseNumber();
        }
        if ($("#form_step00startrev #disc_reserves_money").length > 0) {
            disc_reserves = $("#form_step00startrev #disc_reserves_money").val().ins_parseNumber();
        }
        //할인이 요금합계를 넘지 않도록.
        if (disc_reserves + disc_coupon > fee_sum) {
            disc_reserves = fee_sum - disc_coupon;
            $("#form_step00startrev #disc_reserves_money").val(disc_reserves);
        }
        var final_fee_sum = Math.max(0, fee_sum - disc_coupon - disc_reserves);
        $("#form_step00startrev #final_fee_sum").text(final_fee_sum.ins_addComma());
        var f30 = (final_fee_sum * minPayRaitio).ins_floorNumber(100); //minPayRaitio : 출발일이 가까운 경우 1 값을 가진다.
        if (f30 < 10000) { //계약금 최소금액은 1만원.
            f30 = final_fee_sum;
        }
        var f70 = final_fee_sum - f30;
        $("#form_step00startrev .fee_remain70").each(function () {
            $(this).text(f70.ins_addComma()); //현장결제금액표시부분.
        });
        //금액선택.. 금액계산하기..
        var opt0 = $("#form_step00startrev #dd_pay_amt option:eq(0)");
        var opt1 = $("#form_step00startrev #dd_pay_amt option:eq(1)");
        if (opt0) {
            opt0.val(f30);
            //opt0.text('계약금 : ' + f30.ins_addComma() + '원');
            opt0.text(' ' + f30.ins_addComma() + '원');
            if (f30 > 0) {
                opt0.attr('disabled', false);
                opt1.attr('disabled', true); //가능하면 30%만 받기 강제
                opt1.css('display', "none"); //가능하면 30%만 받기 강제
            }
            else {
                opt0.attr('disabled', true);
                opt1.attr('disabled', false); //가능하면 30%만 받기 강제
                opt1.css('display', ""); //가능하면 30%만 받기 강제
            }
        }
        opt1.val(final_fee_sum);
        if (final_fee_sum > 0) {
            //opt1.text('전액 : ' + final_fee_sum.ins_addComma() + '원');
            opt1.text(' ' + final_fee_sum.ins_addComma() + '원');
        }
        else {
            opt1.text('0원 (결제금액없음)');
        }
        if (f30 == 0) {
            $("#form_step00startrev #dd_pay_amt").val(f70);
        }
        var amt = $("#form_step00startrev #dd_pay_amt option:selected").val().ins_addComma();
        $("#form_step00startrev #fee_payamt").text(amt);
        divPayBalance_change_display();
    }
    $("#form_step00startrev #dd_pay_amt").prop('selectedIndex', 0);
    ComputeFee();
    function CheckUserInput() {
        if (cro == null) {
            alert('렌트카 예약정보가 부정확합니다. 다시 검색후 예약바랍니다.');
            return false;
        }
        var sdt = dayjs(reservation_option.st), edt = dayjs(reservation_option.et);
        if (sdt <= dayjs().add(2, 'hours')) {
            alert('대여일시는 최소 2시간 이후 부터 렌트카 예약 가능.');
            return false;
        }
        //var hours = Math.abs(sdt.diff(edt, 'hours'));
        //if (hours > 168) { //ok
        //    alert('최대 7일까지 대여가능. \n 7일 이상 대여하고자 할경우 7일 이하로 예약하시고, 예약요청사항에 메모(요청)하시면 상담후 처리됩니다.\n 7일이상 대여시 중간반납후 재이용 해야함.');
        //    return;
        //}
        if (edt.hour() < 8) {
            if (!reservation_option['instype'] || (reservation_option['instype'].indexOf('완전') < 0 && reservation_option['instype'].indexOf('고급') < 0)) {
                alert('08시 이전 반납인 경우 고급(완전)자차 가입필수 입니다. \n차량 검색시 고급자차를 선택하고 검색하여 예약바랍니다.');
                return false;
            }
        }
        $('#div_agree_rules').trigger('checkOK');
        var agree = $('#div_agree_rules').data('isok');
        if (!agree) {
            alert('예약 및 이용관련 규정에 동의해야 예약을 진행 할 수 있습니다.');
            return false;
        }
        var $form = $('#form_step00startrev');
        reservation_option['name'] = $form.find("input[name='i_Name']").val().trim();
        reservation_option['phone'] = $form.find("input[name='i_Phone']").val().trim();
        reservation_option['email'] = $form.find("input[name='i_Email']").val();
        reservation_option['msg'] = $form.find("textarea[name='i_Msg']").val();
        if (reservation_option.name.length < 2 || reservation_option.name.length > 20) {
            alert('이름 입력을 확인하세요. 필수(최대20자)');
            return false;
        }
        var regex_str = /^\d{2,3}-?\d{2,4}-?\d{4,8}$/;
        if (reservation_option['phone'].match(regex_str) == null) {
            alert('휴대전화번호를 확인하세요(알림 문자용도 입니다). 필수!');
            return false;
        }
        var license_dur = $("#form_step00startrev :input:radio[name=radio_license_dur]:checked").val();
        if (license_dur != 'yes') {
            alert('자격조건을 만족해야 렌트카 대여 가능합니다.');
            document.getElementById('agree_rental_condition').scrollIntoView();
            return false;
        }
        reservation_option['가능수'] = $("#form_step00startrev #h_p_count").val();
        reservation_option['회원id'] = $("#form_step00startrev #h_memberid").val();
        var cdiscctl = $("#form_step00startrev #disc_coupon_fee");
        reservation_option['쿠폰아이디'] = cdiscctl.length > 0 ? cdiscctl.prop('couponid') : null;
        reservation_option['쿠폰할인금액'] = cdiscctl.length > 0 ? cdiscctl.text().ins_removeComma() : 0;
        reservation_option['전용쿠폰번호'] = cdiscctl.length > 0 ? cdiscctl.prop('o_c_num') : null;
        if (reservation_option['쿠폰할인금액'] > 0 && reservation_option['쿠폰아이디'] == null) {
            alert('할인쿠폰 적용 오류입니다.');
            return false;
        }
        var pamtctl = $("#form_step00startrev #disc_reserves_money");
        reservation_option['포인트사용금액'] = pamtctl.length > 0 ? pamtctl.val().ins_removeComma() : 0;
        if (+reservation_option['포인트사용금액'] > 0) {
            var max = (pamtctl.prop('max') || '0').ins_parseNumber();
            if (reservation_option['포인트사용금액'] > max) {
                alert('포인트사용금액 오류입니다.');
                return false;
            }
        }
        reservation_option['할인요금'] = $("#form_step00startrev #final_fee_sum").length > 0 ? $("#form_step00startrev #final_fee_sum").text() : $("#form_step00startrev #h_feesum").val().ins_removeComma();
        reservation_option['결제예정금액'] = $("#form_step00startrev #dd_pay_amt option:selected").val().ins_removeComma();
        if (reservation_option['결제예정금액'] <= 0) {
            alert('결제금액이 0원인경우 예약진행 불가합니다.');
            return false;
        }
        reservation_option['EvtIDs'] = $("#applyeventdiv input:checkbox[name='chk_event']:checked").map(function (idx) { return $(this).val(); }).get().join();
        return true;
    }
    deleteCookie(localStorage.getItem('new_moid')); //카드결제 창 띄웠다가 닫기 반복하면 쿠기가 쌓여서 오류발생함..
    reservation_option['new_moid'] = '';
    //카드결제 모달 띄우기..
    $("#form_step00startrev #btn_payCardModal").click(function (event) {
        event.preventDefault();
        deleteCookie(localStorage.getItem('new_moid')); //카드결제 창 띄웠다가 닫기 반복하면 쿠기가 쌓여서 오류발생함..
        console.log('cookie deleted!');
        if (!CheckUserInput()) {
            return;
        }
        reservation_option['new_moid'] = '';
        //https://www.thereformedprogrammer.net/asp-net-core-razor-pages-how-to-implement-ajax-requests/
        $.ajax({
            type: "POST",
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(reservation_option),
            url: '/dorev/step00startrev?handler=DoRevForPrepareCardPay&' + Date.now(),
            headers: { RequestVerificationToken: $('input:hidden[name="__RequestVerificationToken"]').val() },
            beforeSend: function (xhr) {
                $('#form_step00startrev #div_errormsg').css('display', 'none');
            },
            success: function (response) {
                if (response.ok == 'true' || response.ok == true) {
                    reservation_option['new_moid'] = response['new_moid'];
                    PopupCardPay();
                }
                else {
                    $('#form_step00startrev #div_errormsg').css('display', 'block');
                    $('#form_step00startrev #div_errormsg #errtext').text(response.msg);
                }
            },
            error: function (response) {
                alert('오류: 다시시도해주세요.');
            }
        });
    });
    function PopupCardPay() {
        localStorage.setItem('new_moid', 'coo_reservation_option_' + reservation_option['new_moid']);
        //setCookie('coo_reservation_option_' + reservation_option['new_moid'], JSON.stringify(reservation_option), 3); //쿠키 더이상 사용 안함.
        //console.log(reservation_option);
        var amt = +reservation_option['결제예정금액'];
        //결제진행하기전에 암호화키 가지고와서 세팅하기
        $.ajax({
            type: "POST",
            url: '/api/smartro/payinfo?handler=encryptData&' + Date.now(),
            data: { amt: amt },
            headers: { RequestVerificationToken: $('input:hidden[name="__RequestVerificationToken"]').val() },
            success: function (response) {
                $("#rev_card_form #MID").val(response.mid);
                $("#rev_card_form #Amt").val(amt);
                $("#rev_card_form #UserIP").val(response.clientip);
                $("#rev_card_form #EncryptData").val(response.encryptData);
                $("#rev_card_form #ediDate").val(response.ediDate);
                var dinfo = ""; //부가정보. 
                $("#rev_card_form #MallReserved").val(dinfo);
                goPay_Smartro(reservation_option);
            },
            error: function (response) {
            }
        });
    }
    //은행송금 모달 띄우기..
    $("#form_step00startrev #btn_payCashModal").click(function (event) {
        event.preventDefault();
        $("#form_step00startrev #payCashModal #msg").css('display', 'none');
        if (!CheckUserInput()) {
            return;
        }
        $('#payCashModal').modal({ backdrop: 'static' });
    });
    //은행송금 처리 저장하기...
    $("#form_step00startrev #btn_SavePayCashModal").click(function () {
        if (!CheckUserInput()) {
            return;
        }
        $("#form_step00startrev #btn_SavePayCashModal").prop("disabled", true);
        $("#form_step00startrev #payCashModal #msg").css('display', 'inline');
        $("#form_step00startrev #payCashModal #msg").text("잠시만기다리세요. 처리중입니다.");
        //http://www.talkingdotnet.com/handle-ajax-requests-in-asp-net-core-razor-pages/
        $.ajax({
            type: "POST",
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(reservation_option),
            url: '/dorev/step00startrev?handler=DoRevPayBank&' + Date.now(),
            headers: { RequestVerificationToken: $('input:hidden[name="__RequestVerificationToken"]').val() },
            success: function (response) {
                $("#form_step00startrev #btn_SavePayCashModal").removeAttr("disabled");
                if (response.ok == 'true' || response.ok == true) {
                    $("#form_step00startrev #payCashModal #msg").css('display', 'none');
                    $('#form_step00startrev #payCashModal').modal('hide');
                    deleteCookie('coo_reservation_option');
                    setCookie('coo_go_rev_info', JSON.stringify({ revnum: response.revnum, name: response.name }), 1 / 6);
                    window.location.href = '/reservation/cashpaydone';
                }
                else {
                    $("#form_step00startrev #payCashModal #msg").text("오류가 발생하여 예약접수에 실패하였습니다." + response.msg);
                }
            },
            error: function (response) {
                $("#form_step00startrev #btn_SavePayCashModal").removeAttr("disabled");
                $("#form_step00startrev #payCashModal #msg").text("오류가 발생하여 예약접수에 실패하였습니다.");
            }
        });
    });
}
