import $ from 'jquery';
import 'jquery-validation';
export function login_account() {
    console.log('login_account');
    $("#userlogin_form #uid").keydown(function () {
        $("#login_fail_div").css('display', 'none');
        $('#userlogin_form #btn_login').attr("disabled", false);
    });
    $("#userlogin_form #upw").keydown(function () {
        $("#login_fail_div").css('display', 'none');
        $('#userlogin_form #btn_login').attr("disabled", false);
    });
    $('#userlogin_form #btn_login').on('click', function (e) {
        e.preventDefault();
        var purl = '/api/account/login';
        var $form = $('#userlogin_form');
        $('#userlogin_form #btn_login').attr("disabled", true);
        if ($form.valid()) {
            //http://www.talkingdotnet.com/handle-ajax-requests-in-asp-net-core-razor-pages/
            $.ajax({
                type: "POST",
                url: purl + '?handler=Login&' + Date.now(),
                data: {
                    memberId: $form.find("input[name='ID']").val(),
                    memberPassword: $form.find("input[name='Password']").val()
                },
                headers: { RequestVerificationToken: $('input:hidden[name="__RequestVerificationToken"]').val() },
                //beforeSend: function (xhr) { xhr.setRequestHeader("XSRF-TOKEN", $('input:hidden[name="__RequestVerificationToken"]').val());},
                success: function (response) {
                    if (response.ok == 'true' || response.ok == true) {
                        var rurl = window.location.href.ins_getQueryString('ReturnUrl');
                        if (rurl != null) {
                            window.location.href = decodeURIComponent(rurl);
                        }
                        else {
                            if ('referrer' in document && document.referrer.indexOf('account') < 0) {
                                window.location.href = document.referrer;
                            }
                            else {
                                window.location.href = "/";
                            }
                        }
                    }
                    else {
                        $("#login_fail_div").css('display', 'block');
                        $('#userlogin_form #btn_login').attr("disabled", false);
                    }
                },
                error: function (response) {
                    $("#login_fail_div").css('display', 'block');
                    $('#userlogin_form #btn_login').attr("disabled", false);
                }
            });
        }
    });
    $('#btn_non_member').on('click', function (e) {
        e.preventDefault();
        var ret_url = decodeURIComponent(window.location.href.ins_getQueryString('ReturnUrl'));
        window.location.href = ret_url;
    });
}
