import $ from 'jquery';
export function member_submenu() {
    var pathname = window.location.pathname.toLowerCase();
    if (pathname.indexOf('/member/coupon') >= 0) {
        $('#member_submenu #coupon').addClass('active');
    }
    else if (pathname.indexOf('/member/history') >= 0) {
        $('#member_submenu #history').addClass('active');
    }
    else {
        $('#member_submenu #info').addClass('active');
    }
}
